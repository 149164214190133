//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    }
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '542':
        case '543':
        case '553':
        case '556':
        case '561':
        case '562':
        case '565':
        case '570':
          return [
            'Luz a precio de coste en todos los tramos.',
            'Aprovecha las bajadas de precio al momento.',
            'Energía y potencia a precio de coste sin márgenes asociados.'
          ];
        default:
          return [];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: this.offer.precioMensual
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    }
  }
};
